<template>
  <v-fade-transition mode="out-in" >
    <router-view />
    <!-- <v-dialog v-model="LoginInvalidObj"></v-dialog> -->
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      configlist: {},
      LoginInvalidObj:{
        dialog:false,
        text:this.$t('登录失效，请重新登录')
      }
    };
  },
  created(){
    //  this.getlang();
     setTimeout(() => {
        let config=localStorage.getItem('fanli_config')
        
        let fb_code=JSON.parse(config) ? JSON.parse(config).fb_code :'105946607929027'
        if(fb_code){
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", fb_code);
            chatbox.setAttribute("attribution", "biz_inbox");
            window.fbAsyncInit = function () {
            FB.init({
                xfbml: true,
                version: 'v16.0'
              });
            };
        
            (function (d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s);
              js.id = id;
              js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
     }, 1300);
  },
  mounted() {
    //如果没有网站配置 则请求
    this.$api.home.banner().then((res) => {
      if (res.status) {
          this.$store.commit("setconfig", res.data);
      }
    });
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    },
    //获取语言货币列表
    getlang() {
      this.$api.home.get_lang({lang:this.$store.state.statedeflang ? this.$store.state.statedeflang.id : "vi",type:'home'}).then((res)=>{
        if(res.status=='success'){
          this.$store.commit("setLangJson", res.data);
          // let item ={name:"Tiếng Việt",icon:require("./assets/images/icon/vietnam.svg"),id:'vi'}
          // this.$store.commit("setdeflang", item);
        }
      })
     
    }
  },
};
</script>

<style lang="scss"></style>
