import axios from 'axios';
import qs from 'qs';
import store from '../store';
import router from "../router";
import Toast from '../components/Toast' //弹窗组件
const DefaultParam = {
  repeatable: false
};
let ajax = {
  PREFIX: store.state.domainurl,
  requestingApi: new Set(),
  getQueryString: function (url) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  },
  extractUrl: function (url) {
    return url ? url.split('?')[0] : '';
  },
  isRequesting: function (url) {
    let api = this.extractUrl(url);
    return this.requestingApi.has(api);
  },
  addRequest: function (url) {
    let api = this.extractUrl(url);
    this.requestingApi.add(api);
  },
  deleteRequest: function (url) {
    let api = this.extractUrl(url);
    this.requestingApi.delete(api);
  },
  get: function (url, param, extendParam) {
    let params = {
      url,
      method: 'GET'
    };
    if (param) {
      params.params = param;
    }
    return this.ajax(params, extendParam);
  },
  post: function (url, param, extendParam) {
    var params = {
      url,
      method: 'POST'
    };

    if (!param) {
      let param = {};
    }
    // if (param) params.data = qs.stringify(param);
    if (param) params.data = param;
    return this.ajax(params, extendParam);
  },
  postJson: function (url, paramJson, extendParam) {
    return this.ajax({
      url,
      method: 'POST',
      data: paramJson
    }, extendParam);
  },
  patchJson: function (url, paramJson, dataType, extendParam) {
    return this.ajax({
      url,
      method: 'PATCH',
      data: paramJson
    }, extendParam);
  },
  delete: function (url, extendParam) {
    return this.ajax({
      url: url,
      method: 'DELETE'
    }, extendParam);
  },
  ajax: function (param, extendParam) {
    let USERINFO_ = store.state.USERINFO,
        defLang=store.state.statedeflang,
      to_ken = '',
      defLangId=1;
      to_ken=store.state.token
    let get_lang_id = this.getQueryString('langid')
    if(get_lang_id){
      defLangId = get_lang_id
    }else if(defLang){
      defLangId=defLang.id
    }

    let params = Object.assign({}, DefaultParam, param, extendParam || {});
    params.crossDomain = params.url.indexOf('http') === 0;
    let url = params.url;
    if (!params.crossDomain) {
      if (process.env.NODE_ENV === 'development') {
        
        this.PREFIX='https://fanliapi.taocarts.com'
      } else if (process.env.NODE_ENV === 'debug') {
        this.PREFIX='https://fanliapi.taocarts.com'
      } else if (process.env.NODE_ENV === 'production') {
        this.PREFIX='https://fanliapi.taocarts.com'
      }
      url = params.url = this.PREFIX + params.url;
    }
    if (params.method != 'GET') {
      if (params.repeatable === false) {
        this.addRequest(url);
      }
    }
    let header={}
    if(to_ken){
      header = {
        "Content-Type": "application/json; charset=utf-8",
        "usertoken": to_ken,
        // "langid":defLangId,
        // "webdomain":store.state.localDomain
      };

    }else {
      header = {
        "Content-Type": "application/json; charset=utf-8"
      };
    }
    let defaultParam = {
      headers: header,
      responseType: 'json',
      validateStatus: function (status) {
        return true;
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          allowDots: true
        });
      }
    };
    if (params.crossDomain) {
      defaultParam.headers = {};
    }
    let that = this;
    params = Object.assign({}, defaultParam, params);
    return new Promise((resolve) => {
      return axios.request(params).then((response) => {
        that.deleteRequest(params.url);
        let data = response.data;
        let status = response.status;
        // 如果后端统一封装返回，即所有的请求都是200, 错误码由返回结果提供，则使用以下代码获取状态
        let mysttaus = data.status * 1;
        if (mysttaus == -4) {
          store.commit("setTOKEN", null);
          store.commit("setUSERINFO", null);
          router.push('/sign-in');
          return;
        }
        if (status != 200) {
          if (status == 401) {

            router.push('/sign-in');
            // window.top.location = '/sign-in';
            return;
          }
          if (status == 500) {
            this.$Toast({
              content: '后台异常',
              type: 'error'
            })

          } else if (status == 404) {
            this.$Toast({
              content: '请求不存在',
              type: 'error'
            })
            this.$router.push({path:'/error'})

          } else if (status != 200) {
            this.$Toast({
              content: data._msg || '请求异常',
              type: 'error'
            })
          }
        }

        data.ok = data.status == 200;
        resolve(data);
      }).catch(() => {
        that.deleteRequest(params.url);
        resolve({
          ok: false
        });
      });
    });
  }
};
export default ajax;
