import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    msgNum:0,
    config:JSON.parse(localStorage.getItem("localconfig"))?JSON.parse(localStorage.getItem("localconfig")):null,//网站基础配置
    rateList:JSON.parse(localStorage.getItem("rateList"))?JSON.parse(localStorage.getItem("rateList")):null,
    statedeflang:JSON.parse(localStorage.getItem("deflang"))?JSON.parse(localStorage.getItem("deflang")):null,//当前展示语言
    statedefrate:JSON.parse(localStorage.getItem("defrate"))?JSON.parse(localStorage.getItem("defrate")):null,//当前展示货币
    zhurate:JSON.parse(localStorage.getItem("zhurate"))?JSON.parse(localStorage.getItem("zhurate")):null,//主货币
    // domainurl:'',//接口请求域名
    domainurl:'http://www.tbk.com',
    // domainurl:'http://192.168.31.83:8000',
    USERINFO:JSON.parse(localStorage.getItem("USERINFO"))?JSON.parse(localStorage.getItem("USERINFO")):null,//获取用户信息
    token:localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")):null,
    cartnum:0,//购物车数量
    isWeixin:navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1, // 是否在微信浏览器中  false 不是
    isSafari:/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent), // 是否在微信浏览器中  false 不是
    uploadurl:'',//图片上传方法里的返回图片
    localDomain:window.location.hostname=='localhost'?'redshop.cc':window.location.hostname,
    persionUrl:'.redshop.cc',//个人主页域名
    imgUrl:'https://img.redshop.cc',//图片域名
    // langJson:[],//
    langJson:localStorage.getItem("langJson")  ? JSON.parse(localStorage.getItem("langJson")):[],//
  },
  mutations: {
    // 储存消息数量
    setmsgNum(state,data){
      state.msgNum=data;
    },
    setconfig(state,data){
      localStorage.setItem("localconfig",JSON.stringify(data));//存列表
      state.config=data;
    },
    setdeflang(state,data){
      localStorage.setItem("deflang",JSON.stringify(data));//设置当前展示语言
      state.statedeflang=data;
    },
    setdefrate(state,data){
      localStorage.setItem("defrate",JSON.stringify(data));//设置当前展示货币
      state.statedefrate=data;
    },
    setzhurate(state,data){
      localStorage.setItem("zhurate",JSON.stringify(data));//设置网站主货币
      state.zhurate=data;
    },
    setLangJson(state,data){
      
      localStorage.setItem("langJson",JSON.stringify(data));//设置网站语言包数据
      state.langJson=JSON.parse(localStorage.getItem("langJson"));
    },
    setUSERINFO(state,data){
      if(data===null){
        localStorage.removeItem('USERINFO');
        state.USERINFO=null;
      }else{
        localStorage.setItem('USERINFO',JSON.stringify(data))
        state.USERINFO=JSON.parse(localStorage.getItem("USERINFO"))
      }
     
    },
    // 
    setTOKEN(state,data){
      if(data===null){
        localStorage.removeItem('token');
        state.token=null;
      }else{
        localStorage.setItem('token',JSON.stringify(data))
        state.token=JSON.parse(localStorage.getItem("token"))
      }
    },
    setcartnum(state,data){
      state.cartnum=data
    },
    setuploadurl(state,data){
      state.uploadurl=data
    },
    setrateList(state,data){ //获取货币列表
      localStorage.setItem("rateList",JSON.stringify(data));
      state.rateList=data
    }

  },
  actions: {
    checkout ({ commit, state }, products) {
      // 把当前购物车的物品备份起来
      const savedCartItems = [...state.cart.added]
      // 发出结账请求，然后乐观地清空购物车
      commit(types.CHECKOUT_REQUEST)
      // 购物 API 接受一个成功回调和一个失败回调
      shop.buyProducts(
        products,
        // 成功操作
        () => commit(types.CHECKOUT_SUCCESS),
        // 失败操作
        () => commit(types.CHECKOUT_FAILURE, savedCartItems)
      )
    },
    getUser(context) {
      
      context.commit('setUSERINFO')
    }
  },
  modules: {

  }
})
