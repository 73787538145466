import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./lang";
import vuetify from "@/plugins/vuetify";
import api from './api/request'
import store from './store';
import "./plugins";
import "@/assets/scss/_global.scss";
import Toast from './components/Toast' //弹窗组件
import createToast from './plugins/create' //弹窗组件
import * as Filters from './plugins/filter' //过滤器
import PublicMethods from './plugins/until' //全局公用方法
Vue.config.productionTip = false;


// 谷歌统计
function init(google_tongji_token){
  if(google_tongji_token){
    setTimeout(function() {
      // 谷歌统计
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.googletagmanager.com/gtag/js','ga');
        ga('create', google_tongji_token, 'auto') 
        ga('send', 'pageview') // 是否要统计着陆页面访问，取决于你的需求，这个不一定需要，会和`router`统计有重复计算
    }, 10000);
  }
}
api.public.config({}).then((res)=>{
  if(res.status=='success'){
    init(res.data.google_code)
    localStorage.setItem('fanli_config' , JSON.stringify(res.data))
    if(store.state.langJson.length==0){
      LangPack()
    }else {
      console.log('6666');
      initView()
    }
  }
 })
 function LangPack(){
    api.home.get_lang({lang:store.state.statedeflang ? store.state.statedeflang.id : "vi",type:'home'}).then((res)=>{
      if(res.status=='success'){
      let obj={}
      let arr=res.data
      for(var i=0 ; i<arr.length ; i++){
        obj[arr[i].text]=arr[i].to
      }
      store.commit("setLangJson",obj);
      initView()
      }
    })
 }
 

// import Vue plugin
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

// import polyfills for IE if you want to support it
import "vue-svg-inline-plugin/src/polyfills";
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin);
// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
  attributes: {
    data: ["src"],
    remove: ["alt"]
  }
});
// end:: svgrender 
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
Vue.component(VueSlickCarousel)
Vue.component("VueSlickCarousel", VueSlickCarousel);
import VueApexCharts from 'vue-apexcharts'
import { set } from "lodash";
Vue.use(VueApexCharts)

//封装axios
Vue.prototype.$api = api;
//公共方法类
Vue.prototype.$PublicMethods = PublicMethods;
Vue.component('apexchart', VueApexCharts)

Vue.prototype.$Toast = ((obj) => {
  return createToast(Toast, obj).show();
})

//注册全局过滤器
Object.keys(Filters).forEach(key=>{
  Vue.filter(key,Filters[key])
})
function initView(){
  new Vue({
    router,
    vuetify,
    store,
    i18n: i18n.getI18n(),
  
    render: h => h(App)
  }).$mount("#app");
}

  
  



  




