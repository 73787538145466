import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
import zh_CN from './zh.json'
import vi from './vi.json'
import { set } from 'lodash'
Vue.use(VueI18n)
// let obj={}
//   let arr=store.state.langJson>0?store.state.langJson:vi
//   for(var i=0 ; i<arr.length ; i++){
//     obj[arr[i].text]=arr[i].to
//   }
  
function getI18n() {
  const langList = store.state.langJson?store.state.langJson:{};
  const langSign = store.state.statedeflang?store.state.statedeflang.id:"vi";
  const messages = {
    "zh-CN":langList,
    "vi":langList
  }
  // 翻译
  const i18n = new VueI18n({
    locale: langSign,
    messages:messages,
    silentTranslationWarn: true // 注释警告
  })
  return i18n
}
export default {
  getI18n
}