// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/base/Index.vue"),
      children: [
        {
          path: "/",
          name: "Home",
          component: () => import("@/views/home/Home.vue")
        },
        {
          path: "/search-product",
          name: "search-product",
          component: () => import("@/views/home/searchProduct.vue")
        },
        {
          path: "/fanli-Strategy",
          name: "fanli-Strategy",
          component: () => import("@/views/infos/fanli-Strategy.vue")
        },
        {
          path: "/more-Strategy",
          name: "more-Strategy",
          component: () => import("@/views/infos/more-Strategy.vue")
        },
        {
          path: "/strategyDetail",
          name: "strategyDetail",
          component: () => import("@/views/infos/StrategyDetail.vue")
        },
        {
          path: "/StrategyDetailDn",
          name: "StrategyDetailDn",
          component: () => import("@/views/infos/StrategyDetailDn.vue")
        },
        {
          path: "/lose-orders",
          name: "lose-orders",
          component: () => import("@/views/Orders/lose-orders.vue")
        },

        // 邀请
        {
          path: "/invit-index",
          name: "invit-index",
          component: () => import("@/views/invitation/index.vue")
        },
        // 其他
        {
          path: "/download",
          name: "download",
          component: () => import("@/views/other/download.vue")
        },

      ]
    },
    {
      path: "/user",
      component: () => import("@/views/user/user-parent.vue"),
      redirect:'/user-index',
      children: [
        // 个人中心
        {
          path: "/user-index",
          name: "user-index",
          component: () => import("@/views/user/index.vue")
        },
        {
          path: "/user/Withdrawa",
          name: "user-Withdrawa",
          component: () => import("@/views/user/Withdrawa.vue")
        },
        // 返利订单
        {
          path: "/order/fanli-order",
          name: "fanli-order",
          component: () => import("@/views/Orders/fanli-order.vue")
        },
        // 账号设置
        {
          path: "/user/accountSet",
          name: "user-accountSet",
          component: () => import("@/views/user/accountSet.vue")
        },
        // 邀请赚钱
        {
          path: "/user/inviteMoney",
          name: "user-inviteMoney",
          component: () => import("@/views/user/inviteMoney.vue")
        },
        // 消息通知
        {
          path: "/infos/news",
          name: "infos-news",
          component: () => import("@/views/infos/news.vue")
        },
        // 邀请人数明细
        {
          path: "/user/rebateDetails",
          name: "rebateDetails",
          component: () => import("@/views/user/rebateDetails.vue")
        }
      ]
    },
    {
      path: "/error",
      component: () => import("@/views/error.vue")
    },
    {
      path: "/sign-up",
      component: () => import("@/views/SignUp.vue")
    },
    {
      path: "/sign-in",
      component: () => import("@/views/SignIn.vue")
    },
    {
      path: "/forget-pwd",
      component: () => import("@/views/ForgetPwd.vue")
    },
    {
      path: "/mobile-categories",
      component: () => import("@/views/MobileCategories.vue")
    }
  ]
});

export default router;
