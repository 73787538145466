<template>
  <div>
    <v-row>
      <v-col cols="3"  @click="godetail(item.id)" class="d-flex align-center justify-between" v-for="(item , index) in data" :key="index">
        <v-hover v-slot="{ hover }" >
          <v-card
            v-if="index < 4"
            class="mx-auto pa-4 cursor-pointer"
            :flat="hover ? false : true"
          >
            <v-img
              min-width="245"
              :src="item.face"
              height="200px"
            ></v-img>
            <v-card-title class="text-body-1 font-weight-bold min-80">
              {{item.title}}</v-card-title
            >
            <v-card-subtitle class="line2 height-40 mt-2 mb-2 ">
              {{item.content}}。
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-actions class="grey--text">
              <span>{{$t('通知')}}</span>
              <v-spacer></v-spacer>
              <div >{{item.create_time | formFilter}}</div>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "articleCard",
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  methods:{
    godetail(id){
      this.$router.push({
        path:'strategyDetail',
        query:{
          id:id
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.card-hover-shadow {
  cursor: pointer;
  transition: all 250ms ease-in-out 0s;
  &:hover {
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px !important;
    transition: all 250ms ease-in-out 0s;
  }
}
.height-40{
  height: 40px;
}
.min-80{
  min-height: 110px;
}
.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>