//所有过滤器方法

import store from '../store';
import PublicMethods from './until' //过滤器
//货币过滤
function rateFilter(price, showsymbol_=1) {
  let rate = store.state.statedefrate?store.state.statedefrate.rate:1;
  const num =store.state.statedefrate?store.state.statedefrate.keep_decimals:2;
  const is_front_ =store.state.statedefrate?store.state.statedefrate.is_front:1;
  const symbol_ =store.state.statedefrate?store.state.statedefrate.symbol:'$';
  if (price == "") {
    price = 0;
  }

  if (typeof price === 'string') {
    price = parseFloat(price);
  }
  let total = price * rate;

  total = PublicMethods.roundData(total, num);

  total =  PublicMethods.moneyFormat(total);
  total = String(total).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  let returncontent='';
  if(showsymbol_===1){
    if(is_front_==='1'){

      returncontent=symbol_+''+total
    }else{
      returncontent=total+''+symbol_
  
    }
  }else{
    returncontent=total
  }
  
  
  return returncontent;
}


//时间戳转换
function formFilter(timestamp, n) {
  var date = new Date(timestamp * 1000) // 时间戳为10位需*3000，时间戳为13位的话不需乘3000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  if (n) {
    return Y + M + D
  } else {
    return Y + M + D + h + m + s
  }
}

export{
    rateFilter,
    formFilter
}