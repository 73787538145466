<template>
    <div class="text-center d-flex flex-column align-center">
        <div>
            <v-img width="300" :src="imgSrc"> </v-img>
        </div>
        <p class="text-body-1 font-weight-bold">{{$t(describe)}}</p>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props:{
        imgSrc:{
            type:String,
            default:require('@/assets/images/other/wusousuo@2x.png'),
        },
        describe:{
            type:String,
            default:'暂无内容~'
        }
    },
    mounted(){

    },
    methods:{

    }
}
</script>
<style scoped>

</style>